







































import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindContaUseCase } from '@/usecases/conta/FindContaUseCase'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ValidationEmail extends Vue {
	erro = ''
	logo = require('@/assets/almode_256x181.png')
	findContaUseCase = new FindContaUseCase()

	get queryParamsEmail() {
		return this.$route.query.email
	}

	get queryParamsSubdominio() {
		return this.$route.query.subdominio
	}
	
	created(){
		const email = this.queryParamsEmail
		const subdominio = this.queryParamsSubdominio
		if (typeof email !== 'string' || typeof subdominio  !== 'string') {
			AlertModule.setError('Token inválido')
			return
		}
		this.findContaUseCase.validarConta(email, subdominio);
	}

	async login() {
		window.location.href = `${location.protocol}//${location.host}/entrar`
	}
}
